<template>
  <ValidationProvider :name="name" :rules="validationRules" v-slot="{ errors }" slim>
    <div
      class="form-phone-input"
      :class="{
        'form-phone-input--error': errors.length > 0,
        'form-phone-input--active': isFocused || phoneNumber || errors.length > 0
      }"
      v-body-click="{ closeCb: onPhoneInputBlur, condition: isFocused }"
    >
      <input
        id="phone-number"
        class="form-phone-input__field"
        v-model="phoneNumber"
        @focus="onPhoneInputFocus()"
        ref="inputPhoneNumber"
      >
      <div class="form-phone-input__data" v-if="countryCodes.length > 0">
        <span class="form-phone-input__highlight"/>
        <span class="form-phone-input__bar"/>
        <label for="phone-number" class="form-phone-input__label">Phone Number</label>
        <span v-if="errors.length > 0" class="form-phone-input__error">{{ errors[0] }}</span>
        <div class="form-phone-input__button-wrapper">
          <button type="button" class="form-phone-input__dropdown-button" @click="toggleCodeDropdown()">
            <span class="form-phone-input__img-wrapper">
              <img class="form-phone-input__img" alt="country" :src="countryCode.flag">
            </span>
            <svg class="form-phone-input__ico">
              <use href="#arrow-down"/>
            </svg>
          </button>
        </div>

        <div class="form-phone-input__tooltip">
          <InputTooltip>
            Entering your number please use international format and do not include the first <b>0</b> (zero)
          </InputTooltip>
        </div>
        <div
          class="form-phone-input__dropdown"
          :class="{ 'form-phone-input__dropdown--open': isOpenedCodesDropdown }"
          v-body-click="{ closeCb: closeCodeDropdown, condition: isOpenedCodesDropdown }"
        >
          <CountryCodeDropdown
            v-if="isOpenedCodesDropdown"
            :country-codes="countryCodes"
            @country-code-select="updateCountryCode"
          />
        </div>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
  import axios from 'axios';

  import CountryCodeDropdown from './CountryCodeDropdown';
  import InputTooltip from './InputTooltip';
  import Cleave from 'cleave.js';

  require('cleave.js/dist/addons/cleave-phone.i18n');

  const DEFAULT_COUNTRY_CODE = 'GB';

  export default {
    components: { CountryCodeDropdown, InputTooltip },
    props: ['value', 'name', 'validationRules'],
    data() {
      return {
        isFocused: false,
        countryCodes: [],
        countryCode: null,
        isOpenedCodesDropdown: false,
        phoneNumber: ''
      }
    },
    created() {
      axios.get('https://restcountries.com/v2/all?fields=name,callingCodes,flag,alpha2Code').then((response) => {
        this.countryCodes = response.data;
        this.countryCode = this.countryCodes.find((countryCode) => {
          return countryCode.alpha2Code === DEFAULT_COUNTRY_CODE;
        });
      });
    },
    methods: {
      initCleave() {
        if (this.cleave) { this.cleave.destroy(); }
        this.cleave = new Cleave(this.$refs.inputPhoneNumber, {
          phone: true,
          phoneRegionCode: this.countryCode.alpha2Code,
          prefix: `+${this.countryCode.callingCodes[0]}`,
        });
      },
      onPhoneInputFocus() {
        this.initCleave();
        if (!this.phoneNumber) {
          this.phoneNumber = `+${this.countryCode.callingCodes[0]}`;
        }
        this.isFocused = true;
        const input = this.$refs.inputPhoneNumber;
        setTimeout(function() { input.selectionStart = input.selectionEnd = 10000; }, 0); // scroll to end of input
      },
      onPhoneInputBlur() {
        this.isFocused = false;
        if (this.phoneNumber.trim().length <= this.countryCode.callingCodes[0].length + 1) {
          this.phoneNumber = '';
        }
        this.$emit('input', this.phoneNumber);
      },
      toggleCodeDropdown() {
        this.isOpenedCodesDropdown = !this.isOpenedCodesDropdown;
      },
      closeCodeDropdown() {
        this.isOpenedCodesDropdown = false;
      },
      updateCountryCode(countryCode) {
        this.countryCode = countryCode;
        setTimeout(() => {
          this.phoneNumber = '';
          this.closeCodeDropdown();
          this.$refs.inputPhoneNumber.focus();
        });
      }
    }
  }
</script>

<style scoped>
  .form-phone-input {
    margin-top: 15px;
    padding-bottom: 23px;
    position: relative;
  }

  .form-phone-input--active {
    .form-phone-input__field {}

    .form-phone-input__label {
      top: -14px;
      font-size: 0.75rem;
      text-transform: uppercase;
      color: var(--primary-color);

    }

    .form-phone-input__bar {
      &:after {
        width: 100%;
      }
    }

    .form-phone-input__button-wrapper {
      display: flex;
    }
  }

  .form-phone-input--error {
    .form-phone-input__error {
      display: block;
    }

    .form-phone-input__field {
      &:not(:placeholder-shown) ~ .form-phone-input__data {
        .form-phone-input__label {
          color: var(--error-color);
        }

        .form-phone-input__bar {
          &:after {
            background-color: var(--error-color);
          }
        }
      }
    }
  }

  .form-phone-input__field {
    background: none;
    color: var(--color-black);
    font-size: 1.25rem;
    line-height: 1.2;
    padding: 5px 25px 5px 50px;
    display: block;
    width: 100%;
    height: 34px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #DFE3E8;
    box-sizing: border-box;

    &::placeholder {
      display: none;
      color: var(--text-color-tertiary);
    }

    &:-ms-input-placeholder {
      display: none;
      color: var(--text-color-tertiary) !important;
    }

  }

  .form-phone-input__label {
    color: var(--text-color-primary);
    font-size: 1.25rem;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 1px;
    transition: 300ms ease all;
  }

  .form-phone-input__bar {
    position: relative;
    display: block;
    width: 100%;

    &:after {
      content: '';
      height: 1px;
      width: 0;
      bottom: 0;
      position: absolute;
      background: var(--primary-color);
      transition: 300ms ease all;
      left: 0;
      z-index: 1;
    }
  }

  .form-phone-input__tooltip {
    position: absolute;
    top: 2px;
    right: 0;
    width: 20px;
    height: 29px;
  }

  .form-phone-input__button-wrapper {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    z-index: 1;
    height: 29px;
  }

  .form-phone-input__dropdown-button {
    position: relative;
    height: 100%;
    width: 39px;
  }

  .form-phone-input__img-wrapper {
    position: absolute;
    left: 0;
    bottom: 8px;
    width: 19px;
    height: 15px;
  }

  .form-phone-input__img {
    max-width: 100%;
    height: auto;
  }

  .form-phone-input__ico {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 12px;
    height: 7px;
    fill: var(--primary-color);
  }

  .form-phone-input__dropdown {
    display: none;
    position: absolute;
    top: calc(100% - 10px);
    left: 0;
    width: 100%;
    background-color: var(--color-white);
    z-index: 3;
  }

  .form-phone-input__dropdown--open {
    display: block;
  }

  .form-phone-input__error {
    position: absolute;
    left: 0;
    bottom: 6px;
    display: none;
    color: var(--error-color);
    font-size: 0.75rem;
    line-height: 1.33;
  }
</style>
