import { render, staticRenderFns } from "./PricingHeader.vue?vue&type=template&id=3191fa96&scoped=true&"
var script = {}
import style0 from "./PricingHeader.vue?vue&type=style&index=0&id=3191fa96&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3191fa96",
  null
  
)

export default component.exports