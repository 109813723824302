var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pricing-plan",class:{
  'pricing-plan--starter': _vm.isStarterPlan(_vm.planDetails.type),
  'pricing-plan--professional': _vm.isProfessionalPlan(_vm.planDetails.type)
}},[_c('div',{staticClass:"pricing-plan__header"},[_c('p',{staticClass:"pricing-plan__name"},[_vm._v(_vm._s(_vm.planDetails.name))]),_vm._v(" "),_c('svg',{staticClass:"pricing-plan__ico"},[_c('use',{attrs:{"href":'#plan-' + _vm.planDetails.ico}})])]),_vm._v(" "),_c('div',{staticClass:"pricing-plan__data"},[_c('div',{staticClass:"pricing-plan__box pricing-plan__box--price"},[_c('div',{staticClass:"pricing-plan__price-wrapper"},[(_vm.planDetails.price)?_c('span',{staticClass:"pricing-plan__currency"},[_vm._v("£")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"pricing-plan__price"},[_vm._v("\n          "+_vm._s(_vm.priceText)+"\n        ")])]),_vm._v(" "),_c('span',{staticClass:"pricing-plan__text"},[_vm._v(_vm._s(_vm.withQuantity ? _vm.planDetails.priceText : _vm.planDetails.pricePerUserText))])]),_vm._v(" "),(_vm.withQuantity)?_c('div',{staticClass:"pricing-plan__box pricing-plan__box--counter"},[_c('div',{staticClass:"pricing-plan__counter"},[_c('PricingCounter',{attrs:{"is-disabled":_vm.isStarterPlan(_vm.planDetails.type)},on:{"input":_vm.updatePriceText},model:{value:(_vm.planDetails.employeesQuantity),callback:function ($$v) {_vm.$set(_vm.planDetails, "employeesQuantity", $$v)},expression:"planDetails.employeesQuantity"}})],1),_vm._v(" "),_c('span',{staticClass:"pricing-plan__counter-text"},[_vm._v("Active users")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"pricing-plan__list"},_vm._l((_vm.planDetails.features),function(feature){return _c('div',{staticClass:"pricing-plan__item"},[_c('svg',{staticClass:"pricing-plan__bullet"},[_c('use',{attrs:{"href":"#checkmark"}})]),_vm._v("\n        "+_vm._s(feature)+"\n      ")])}),0),_vm._v(" "),_c('div',{staticClass:"pricing-plan__box pricing-plan__box--button"},[_c('div',{staticClass:"pricing-plan__button-wrapper"},[_c('ButtonRippleEffect',{class:{
            'ripple-outer--light': _vm.isStarterPlan(_vm.planDetails.type),
            'ripple-outer--dark': _vm.isBasicPlan(_vm.planDetails.type),
          }},[_c('button',{staticClass:"pricing-plan__button button button--large",class:{
              'button--stroke': _vm.isStarterPlan(_vm.planDetails.type),
              'button--secondary': _vm.isBasicPlan(_vm.planDetails.type),
              'button--primary': _vm.isProfessionalPlan(_vm.planDetails.type)
            },attrs:{"type":"button"},on:{"click":function($event){return _vm.startPackagePlan(_vm.planDetails)}}},[_vm._v("\n            "+_vm._s(_vm.planDetails.buttonTitle)+"\n          ")])])],1),_vm._v(" "),_c('p',{staticClass:"pricing-plan__hint",domProps:{"innerHTML":_vm._s(_vm.withQuantity ? _vm.planDetails.hint : _vm.planDetails.description)}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }